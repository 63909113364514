//                                            `/+o/.
    //                        .+sso+/:oydyo/:-:+shdys/    `-:.     `-/+o+/`
    //                    `/sdh+/::/::ss:`ymdhyso//hmMNyhNNms+ososys+/-:/shms/`
    //                 .+hNNy++oo+/.`.--/osyhdmNNMMMMMMMMMNdsssssoso+hhhhsoo+ymdo.
    //               -smNy/+ymmmmmNNNNMNMMMMMNNNmmNMMMMMMMMMho:///:--shydNMMNdo-sNs`
    //             -hNd+-sNMNdmNMMMNNNMNNNMMMddNMMNNmNMMMMMMNmy+///::/:-:/++ymNNdmMN:
    //           `sNMs`+NMNNNMMMMNNNMMMMMMNmhyso///+ohMmoNMmoo+/::/-:oymNNmsosshdhmMM/
    //          +NMMy`hMMMhyNMNMMNNNMds:-.`-:syddmNMMmyo`+yMMho:..-+//++omMNNNNNNNmdNMs
    //        :mMMMh`yMNdodNNNMNMMMs.+sdmmmmmdhNMMMNhy/..`-syhNmdyssso+/.`:yNMMMMNMNMMMy
    //       :NMNh:-+MMh+mdNNNNNMd.+NNMMMMMMMMmho:-......:--::ohNMMMMMMNmNy/.oNMNmNMNMMMs
    //      :NMm+/dmmMNydyhNdhMMN.yMMNmhysso+:-``        ```.--:/+sdMMMMMNNNm:-mMNNNNMMMMy
    //     :NMy/hNMMMMmNddsh/NmMy-Mms:..`.--.`                ``..-.:yNMMMMNMNs:NMMMNNNNMMy
    //    :NNy/mMMMMMMmNMMshsNdMo/d-...``                       ```...-yMMMNNMd`NMMNMdmoNMM-
    //   /mMm+NMNNMMNMNNNNNNNNMMmom/                              ```..`+NMMMMh`NMMMMNNdhNMh
    //  +NMMmmMNyNMNMMMMMNmmmNMdNNyh+.                             ``````/NMMM::MMMMNMNNmNMN
    // +MNNMMMNymMNNMMMNNNNNMh+:+dNmddhyoo+`                        ````.`sMMN`sMNNMNNMNNNNN
    // dNNNMNNddMNNNNNNmymMN+---::/shdhyyy:                         `````..hMo.NMNMNMMMNmMMd
    // dNNNMMNmNNNmmNMNdNMM+.-..----.-:::.                          ````...:mh/NMMMNMMMNNMMh
    // sMNNMMNMNNmyNMNdmNMo--.....                                  ``...---:dNMNMMNMMNNNMMN.
    // :NNNMMMNNNsmMNmMNMy...`.-.`                                    `.-----:odNmmNMMMMMNMMo
    // .NMMMmMMMNmMNNNNMm:-.```..                                       ``-----:/o//dMMMNMMMm
    // .NMMMNMMNMMNMNNNNs--.``...                                          `....---..dMNMMMMM`
    // .NNMMNNNNNMMMNNNN:-...`...                                           ```.....`+MMMMMMM.
    // .MNNNNNNNMMMMMNNy.......-.`                                          ``..---.`.NMMMMMM`
    // `NMNMMNNNMMNMMMm-...`.-----.`                                        ``.-----.`yMMMMMd
    //  dMMMNNNNMMNNMMo`-....----..`          ``                      `.`` ```.------`:MMMMM-
    //  /MMNMNNNMMNMMN-`.`..-.--.` `--..-:-.-.``..``               ```.-......--.----..NMMMd
    //  `mMNMNNMMMNNMN.``...-.-../hddyysyhysyyso+--/::-..--...----:::+syyyyhhdddy+:-.-.hMMM:
    //   :NNNNNNMMMMMN.`....--.:dy/:-.-/+++ososss+/:+shyo/::/:+os+:+syosyoso+/://ss//.`+MMm
    //    +MdmNNMNMMMN+.--....:+-.-:+ooymdddmdhyo++ss+/yMo.`..oNsyhdhmdmmmmNmdo:-.--:+-:MM/
    //   `y/..-+dNNMMMo-shhyo++--+sso-`dsymoso.smyso+//.od+/:/ho+yyhd/ymsNhyy./yy/``.-hhmm`
    //   .s+md+- oMMMm``.-/sy//-.+/s.  odys+s-  /shyso+.sm+:::yd/hh+:`.hyyhy- `/y/.` `hs/s`
    //   -oyMNyhs:NMMo     `.-`         .---` ``.`/::+s/ms````-mo+:`````.--` ````     `sNm`
    //   `hsMh`.hymMM:       `-         `          .:+:hy`     od:-`                  .+sM-``
    //    o+o/``-/mMM-        .-                ``.```hy`       s.`.`                 -/+M+``
    //   .s `./NMMMM-         --            ````  `:ho`        .s`  ```             ./.+My`
    //    /: `+MMdMM/          -.  `       `   ..+++-           :d/.             ``:o-`oMy
    //     o. .sdNMMm`            `--:://+//.`-///:.           `.ohooo:-.`` `.-:+//:..`hMy
    //     `s```.yMMMs                  ```     .y+  `::.:----.-``o:-::/:::--:::-----..mMo
    //      :s` `oMNMN-                         :N+  -NNhy/:/sds./:..----------------`/MN.
    //       +o``-NMNMd`                      `-syyoo++/.++:so/+yN+..--....-..-....--`dM+
    //        +:.`oMNNN`                     .:-` `.::.` `--..---/+/---.```........-.:d:
    //         ./++Ny::`                   `--`          .--..-----::-..```......---.s.
    //           `:os.--`                  .`            `.. ``.------.`.```..-----.:o
    //             `h-..`                 ``        .:syy/-/ydho-.--...`````.------.+.
    //              +o`.`                        ./ymNNNNNNNmmNNNh:....``.```.-----:s
    //              `h-`.                    -/+oyo/:----:---.--:+sso:........--::-+:
    //               /d...                 `.++:  -:--/+:/oo+o++-.``--.....-----:-:y
    //               `Md:.`                ``     `-:/+ooooo+/-........-----------yo
    //                mNNs-`                     `..-/oo+://:/oo:......----------os
    //                h:+md:.                  ...``.`         `------.---------++
    //               `h..-+ddo.`                            ``.----------------s:
    //                 h` .--/ydy:`                   `...--------------------+y.
    //                 h`   ..--+yds+.`               `....----------------:+dN`
    //                `y      `.-.-:sdhs:.`    `...````..----------------:smsdm
    //                `h         .--..-+ymdy+/:----:----------------.-/shs+.`os
    //                `h           `..--..:sdmmhyo/::----------::/+syhy/....`+-
    //                -y              `..--..--/oosyyyhhhyyyssoooo/:.`...`.` /-
    //                `.                  `..--.......................````   +`
    //                                       `...------..-.........``
    //                                           ``..-.--........``
    //                                                ```..```





import '/scss🖌️🖌️🖌️/base🔧/normalize.scss'
import '/scss🖌️🖌️🖌️/base🔧/generics.scss'
import '/scss🖌️🖌️🖌️/base🔧/swiper.scss'
import '/scss🖌️🖌️🖌️/base🔧/♿.scss'


import '/scss🖌️🖌️🖌️/theme/rotate🔄.scss'
import '/scss🖌️🖌️🖌️/theme/scroll.scss'
import '/scss🖌️🖌️🖌️/theme/main🐙.scss'
// import '/scss🖌️🖌️🖌️/theme/grid.scss'

import '/scss🖌️🖌️🖌️/theme/fonts🔤.scss'
import '/scss🖌️🖌️🖌️/theme/wysi.scss'

// import '/scss🖌️🖌️🖌️/theme/uikit⚙️.scss'


import '/atoms🧿🧿🧿/post.scss'
import '/atoms🧿🧿🧿/btn.scss'
import '/atoms🧿🧿🧿/Link/link.scss'
import '/atoms🧿🧿🧿/social.scss'
import '/atoms🧿🧿🧿/icon.scss'
import '/atoms🧿🧿🧿/cat.scss'
import '/atoms🧿🧿🧿/label.scss'
import '/atoms🧿🧿🧿/arrow.scss'
import '/atoms🧿🧿🧿/pag.scss'

import '/components🦾🦾🦾/Blocks/Ccontact/index.scss'
import '/components🦾🦾🦾/Blocks/Cnewsletter/index.scss'
import '/components🦾🦾🦾/Blocks/Cctablocks/index.scss'
import '/components🦾🦾🦾/Footer🔥'

import browser from './browser🕸️.js'
import loadRestApi from './firstload📊.js'


import App from '/main🐙🐙🐙'

import SplitType from 'split-type'

// import FontFaceObserver from 'fontfaceobserver'
import CustomEase from 'gsap/CustomEase'
import { gsap,Power2 } from "gsap"

if (window.history.scrollRestoration) {
  window.history.scrollRestoration = 'manual'
}



document.documentElement.style.setProperty("--ck_hvar", window.innerHeight+'px')
document.documentElement.classList.add('lenis-stopped')

if(import.meta.env.DEV == true){

  document.documentElement.classList.add('dev')
  
  
}
browser.revCheck()

const global = browser.browserCheck()
window.gsap = gsap
window.Power2 = Power2
window.gsap.registerPlugin(CustomEase)
window.gsap.ticker.remove(window.gsap.updateRoot)

CustomEase.create("jumpy","M0,0 C0,0 0.01469,0.00118 0.0225,0.00384 0.03114,0.00678 0.0373,0.01003 0.045,0.01531 0.05437,0.02175 0.06019,0.027 0.06833,0.03531 0.07729,0.04446 0.08301,0.05052 0.09,0.06126 0.10835,0.08948 0.12012,0.10726 0.135,0.13783 0.15528,0.1795 0.16554,0.20527 0.18167,0.24958 0.20191,0.30521 0.21165,0.33689 0.22833,0.39428 0.2477,0.46092 0.25693,0.49719 0.27333,0.565 0.2925,0.64425 0.3017,0.68628 0.31833,0.76635 0.33738,0.85807 0.35992,0.98074 0.36333,0.99833 0.367,0.98976 0.39079,0.92757 0.41167,0.88536 0.42663,0.8551 0.43825,0.83737 0.45667,0.80962 0.46416,0.79833 0.47043,0.792 0.48,0.7824 0.4875,0.77488 0.49296,0.77021 0.50167,0.7645 0.50943,0.75941 0.51555,0.75621 0.52417,0.75343 0.53199,0.75092 0.53854,0.74986 0.54667,0.75001 0.55484,0.75016 0.5614,0.75142 0.56917,0.75426 0.57814,0.75754 0.58445,0.76112 0.5925,0.76674 0.60126,0.77286 0.60664,0.7778 0.61417,0.78571 0.62376,0.79581 0.62997,0.80237 0.6375,0.81408 0.65594,0.84278 0.66746,0.86097 0.6825,0.89204 0.70173,0.93177 0.72026,0.98293 0.72667,0.99834 0.73065,0.99401 0.74305,0.9793 0.75417,0.9685 0.76124,0.96162 0.76611,0.95737 0.77417,0.95216 0.782,0.94709 0.78804,0.94381 0.79667,0.941 0.80448,0.93846 0.81103,0.93739 0.81917,0.93751 0.82734,0.93764 0.83384,0.93894 0.84167,0.94167 0.85002,0.94459 0.85584,0.9478 0.86333,0.95292 0.87204,0.95887 0.87749,0.96358 0.885,0.97126 0.89458,0.98107 0.90284,0.99244 0.90833,0.99896 0.91422,0.99633 0.92376,0.99071 0.93333,0.98778 0.94128,0.98534 0.94771,0.98424 0.95583,0.98439 0.96401,0.98455 0.97051,0.98589 0.97833,0.98865 0.98669,0.99161 1,1 1,1 ")


window.SplitType = SplitType

// EL TIPO DE INCREMENTO PARA TAMAÑOS POR ENCIMA DE DISEÑO
global.design = {
  L:{
    w : 1440,
    h : 800,
    multi:.3,
    total:0,
    ratio:5.56,
    wide:((window.innerHeight*10)/window.innerWidth).toFixed(2),
    
  },
  P:{
    w:375,
    h:640,
    multi:.8,
    total:0
  }
}

// console.log(global.design.L.ratio)
// console.log(global.design.L.wide)

// EL MULTI, si queremos no crezcan los elemento sería 1
// si queremos que crezca de manera natural el rem, se pondría a 0

global.design.L.total = ( ( global.design.L.w / window.innerWidth ) * 10 )
// SE COGE LA DIFERENCIA ENTRE AMBAS Y SE HACE EL MULTI
global.design.L.total = 10 - ((10 - global.design.L.total) * global.design.L.multi)
// UN MATH.MIN PARA QUE NO SE PASE LA REDUCCIÓN Y HAGA EL EFECTO REM NATURAL
global.design.L.total = Math.min(10,global.design.L.total)


global.design.P.total = ( ( global.design.P.w / window.innerWidth ) * 10 )
// SE COGE LA DIFERENCIA ENTRE AMBAS Y SE HACE EL MULTI
global.design.P.total = 10 - ((10 - global.design.P.total) * global.design.P.multi)
// UN MATH.MIN PARA QUE NO SE PASE LA REDUCCIÓN Y HAGA EL EFECTO REM NATURAL
global.design.P.total = Math.min(10,global.design.P.total)


//MULTI PARA EL WIDE
// global.design.L.total *=  Math.min(1,(global.design.L.wide/global.design.L.ratio)*1.05)

document.documentElement.style.setProperty("--ck_multiL", global.design.L.total)
document.documentElement.style.setProperty("--ck_multiP", global.design.P.total)


document.documentElement.style.setProperty("--ck_accent", '#fff')
document.documentElement.style.setProperty("--ck_other", '#050505')

//SIZES : Explicados en guides🔪🔪🔪
if(global.isTouch == 1){

  document.documentElement.style.setProperty("--ck_hscr", window.screen.height+'px')
  document.documentElement.style.setProperty("--ck_hvar", window.innerHeight+'px')
  document.documentElement.style.setProperty("--ck_hmin", document.documentElement.clientHeight+'px')
  
}
else{

  document.documentElement.style.setProperty("--ck_hscr", window.innerHeight+'px')
  document.documentElement.style.setProperty("--ck_hvar", window.innerHeight+'px')
  document.documentElement.style.setProperty("--ck_hmin", window.innerHeight+'px')

}
  if(import.meta.env.DEV == true && document.documentElement.classList.contains('logme')){
    const user = await login()
  }

  let content = document.querySelector('#content')


	Promise.all([
    // fetch(document.body.dataset.js+'/wp-json/csskiller/v1/options?device='+global.device+'&webp='+global.webp).then(resp => resp.json()),
		loadRestApi.loadRestApi(
    {
      url:document.body.dataset.js+'/wp-json/csskiller/v1/options',
      device:global.device,
      webp:global.webp,
      id:content.dataset.id,
      template:content.dataset.template,
    })
    
    // new FontFaceObserver('TTFors').load(),
  
		
		
	]).then((loaded) => {
    new App([global,loaded[0]])
  })
  async function login(){
    let info = []
    let formData = new FormData()
    info.push({label:'username', value:'aserver@csskiller.es'})
    info.push({label:'password', value:encodeURIComponent('jy1&K7w)i14O$BDCtC')})
    info.push({label:'remember', value:true})


    formData.set("form", JSON.stringify(info))
    const logtest = await fetch(document.body.dataset.js+'/wp-json/csskiller/v1/login',{
      
      method: 'post',
      body: formData
    })
    const datalog = await logtest.json()

    console.log(datalog)

    if(datalog.errors){
      if(typeof datalog.errors=='string'){
        console.log(datalog.errors)
      }
      else{
        console.log(Object.values(datalog.errors)[0][0])

      }
    }
    else{
      document.body.dataset.nonce=datalog.nonce
      document.body.dataset.user=datalog.user.ID
      document.documentElement.classList.add('logged')

    }

    return datalog
    
}