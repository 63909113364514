
import './index.scss'

export default class {
  constructor (obj,device,touch) {


    this.pos = obj.pos
    this.device = device
    this.touch = touch
    
    this.DOM = {
      el:obj.el
    }


    this.active = 0
    this.isupdate = 1
    
    this.animstick = {
      active:0,
      current:0,
      limit:0,
      start:0,
      prog:0,
      ratio:1
    }
    this.onResize()
  }
  
  create(){

    
    this.animstick.start = (this.DOM.el.getBoundingClientRect().y + window.scrollY).toFixed(0)
    this.animstick.limit = (this.h).toFixed(0)


    // let first = this.animstick.ratio * .8
    
    //TOP
    this.anim = gsap.timeline({paused:true})
    .to(this.DOM.el.parentNode.querySelector('.proyecto_intro_move'),{opacity:0.3,yPercent:60,ease:'none'},0)

    console.log(this.anim)
  }
  
  start(){

    this.active = 1

  }
  
  stop(){
    
    this.active = 0

   

  }
  
  check(entry,pos){

    let vis = false

    if(entry.isIntersecting == true){
      vis = true
      this.start()

    }
    else{
      
      this.stop()
    }

    return vis

  }

  
  update(pos){
    if(!this.animstick || !this.anim ){
      return false
    }
    this.animstick.current = pos
    this.animstick.current = gsap.utils.clamp(0, this.animstick.limit, this.animstick.current)
    this.animstick.prog = (this.animstick.current  / this.animstick.limit).toFixed(4)
    
    this.anim.progress(this.animstick.prog)
  }
  

  initEvents(){
  }

  removeEvents(){

  }
  onResize(){
    
    this.w = window.innerWidth
    this.h = window.innerHeight



    //* El kill y el create solo si el anim está hecho en absoluto y no con porcentaje
    //* Importante que todos los elementos que vayas a matar tengan un fromTo
    if(this.anim){
      this.anim.kill()
    }
    this.create()
    this.update(window.scrollY)

  
  
  }
  
  
  
}