import Page from '/js🧠🧠🧠/page👁️/pagemain.js'

//COMPS
import Intro from './0Intro'
import parIntro from './0Intro/parallax.js'
import Stext from './Stext'



class Home extends Page {
  constructor (main) {
    super(main)
  }

  async create(content,main,temp=undefined) {
    super.create(content,main)
    if(temp!=undefined){

      document.querySelector('#content').insertAdjacentHTML('afterbegin',temp)
    }
    else{
      let data = await this.loadRestApi(this.main.base+'/wp-json/wp/v2/proyecto/',content.dataset.id,content.dataset.template)
      document.querySelector('#content').insertAdjacentHTML('afterbegin',data.csskfields.html)
    }
    this.el = document.querySelector('main')
    

    this.DOM = {
      el:this.el
    }

    await this.loadImages()
    await this.loadVideos()
    


    await this.createComps()
    await this.createIos()
    

    await this.getReady()
  }
  iOpage(animobj){
    if(animobj.el.classList.contains('iO-parIntro')){
      animobj.class = new parIntro(animobj,this.main.device) 
      

    }
    else if(animobj.el.classList.contains('iO-Stext')){
      animobj.class = new Stext(animobj,this.main.device,this.main.isTouch) 
      

    }
    
    return animobj
  }

  
  
  async createComps(){
   


    await super.createComps()
    if(this.DOM.el.querySelector('.proyecto_intro')){
      this.components.intro = new Intro(this.DOM.el.querySelector('.proyecto_intro'),this.main.device)
    
    }
    
    
    if(this.DOM.el.querySelector('.quickbutton')){
      this.quick = 0
      this.DOM.el.querySelector('.quickbutton').onclick = () =>{
       if(this.quick == 0){
        document.documentElement.classList.add('in-quick')
        document.dispatchEvent(this.main.events.openmenu)
        this.quick = 1
       }
       else{
        
        this.quick = 0
        document.documentElement.classList.remove('in-quick')
        document.dispatchEvent(this.main.events.closemenu)
       } 
      }
    }


    if(this.DOM.el.querySelectorAll('.quickEls_ts a')){
      this.DOM.quickels = this.DOM.el.querySelectorAll('.quickEls_ts a')
      this.DOM.quickimgs = this.DOM.el.querySelectorAll('.quickEls_is img')


      for(let [i,a] of this.DOM.quickels.entries()){
        a.onmouseenter = () =>{
          if(this.DOM.el.querySelector('.quickEls_ts a.act')){
            this.DOM.el.querySelector('.quickEls_ts a.act').classList.remove('act')

          }
          if(this.DOM.el.querySelector('.quickEls_is img.act')){
            this.DOM.el.querySelector('.quickEls_is img.act').classList.remove('act')

          }
          this.DOM.quickels[i].classList.add('act')
          this.DOM.quickimgs[i].classList.add('act')
        }

        a.onmouseleave = () =>{
          this.DOM.quickels[i].classList.remove('act')
          this.DOM.quickimgs[i].classList.remove('act')
        }
      }

    }



  }


  async animIntro(val){
    if(this.components.intro){
     await this.components.intro.start()
    }



    return val
   
  }

  async animOut(){

    document.documentElement.classList.remove('in-quick')
    await gsap.to(this.DOM.el,{opacity:0})

  }

}




export default Home