

export default class{
  constructor (obj,touch,canplay) {
    
    this.el = obj.el    

    this.pos = obj.pos
    this.touch = touch
    this.canplay = canplay
    
    this.DOM = {
      el:obj.el,
      video:obj.el.parentNode.querySelector('video'),
    }
    this.auto = false
    if(this.DOM.video.dataset.auto){
      this.auto = true

      this.DOM.video.loop = true
      this.DOM.video.muted = true
      this.DOM.video.setAttribute('webkit-playsinline', 'webkit-playsinline')
      this.DOM.video.setAttribute('playsinline', 'playsinline')


      if(this.touch){

        this.DOM.video.load()
      }
    }


    this.active = 0
    
    
    this.isloaded = 0




    this.create()
  }
  
  create(){

    
  }
  check(entry,pos){
    let vis = false
    let isPlaying = false

      if(entry.isIntersecting==undefined){
        // this.DOM.video.pause()
        return false
      }
      else if(entry.isIntersecting==true){
        if(this.isloaded == 1){
          if(this.touch == 0){
            this.DOM.video.play()

          }
          else{
            this.DOM.video.setAttribute('autoplay', 'true')

          }
        }
        else{
            this.DOM.video.src = this.DOM.video.dataset.lazy
            if(this.touch == 0){
              this.DOM.video.play()

            }
            else{
              this.DOM.video.setAttribute('autoplay', 'true')

            }
           
        }
      }
      else{
        isPlaying = this.DOM.video.currentTime > 0 && !this.DOM.video.paused && !this.DOM.video.ended 
        && this.DOM.video.readyState > this.DOM.video.HAVE_CURRENT_DATA
        if(isPlaying){
          if(this.touch == 1){

            this.DOM.video.setAttribute('autoplay', 'false')
          }
          else{
            this.DOM.video.pause()
          }
        }
      }
     
      
      

    return false

  }
  
  start(){
    this.active = 1
    
  }
  
  stop(){

    this.active = 0

  }

  initEvents(){
    this.DOM.video.oncanplay = () => {
      this.isloaded = 1
      this.DOM.video.classList.add('Ldd')
      
    }
    this.DOM.video.onplay = () => {
      this.DOM.video.isPlaying = true
      
    }
  }
  removeEvents(){
    this.DOM.video.pause()

  }
  update(){
      
      
  }

  onResize(scrollCurrent){
    this.h = window.innerHeight
    this.max =this.el.clientHeight

    this.checkobj = {
      firstin: Math.min(this.h,this.el.clientHeight)*0.92,
      firstout:this.h*0.92,
      secout:this.el.clientHeight*-1,
      limit:this.el.clientHeight

    }
  }
  
}
