
//VIEWS
//🟠🔴🔵🟢🟣🟡⚪⚫🟤
import Home from '/views👁️👁️👁️/⚪Home/home.js'
import Proyectos from '/views👁️👁️👁️/🔵🔵🔵Proyectos/proyectos.js'
import Proyecto from '/views👁️👁️👁️/🔵Proyecto/proyecto.js'
import About from '/views👁️👁️👁️/🟢About/about.js'


import '/views👁️👁️👁️/⚪Home/styles.js'
import '/views👁️👁️👁️/🔵🔵🔵Proyectos/styles.js'
import '/views👁️👁️👁️/🔵Proyecto/styles.js'
import '/views👁️👁️👁️/🟢About/styles.js'

// import Uikit from '/views👁️👁️👁️/Uikit⚙️'
// import Project from '/views👁️👁️👁️/Project🟢'
// import Contact from '/views👁️👁️👁️/Contact🔵'


// import Legal from '/views👁️👁️👁️/🔗Legal'

  




export function createViews(){


  this.pages = new Map()
    this.pages.set('home', new Home(this.main))
    this.pages.set('proyectos', new Proyectos(this.main))
    this.pages.set('proyecto', new Proyecto(this.main))
    this.pages.set('about', new About(this.main))



}

