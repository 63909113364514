
// import './index.scss'
//import SplitType from 'split-type'

export default class {
  constructor (el,device) {


    this.DOM = {
      el:el,
      home1:el.querySelector('.home1 svg'),
      home2:el.querySelector('.home2 svg'),
      home3:el.querySelector('.home3 svg'),
      trazo:el.querySelector('.trazo svg'),
      face:el.querySelector('.face svg'),
    }
    this.device = device
    this.active = 0

    this.create()
    
  }

  async create(){
    this.DOM.home1.querySelector('path').style.strokeDasharray = this.DOM.home1.querySelector('path').getTotalLength()
    this.DOM.home1.querySelector('path').style.strokeDashoffset = this.DOM.home1.querySelector('path').getTotalLength()

    this.DOM.home3.querySelector('path').style.strokeDasharray = this.DOM.home3.querySelector('path').getTotalLength()
    this.DOM.home3.querySelector('path').style.strokeDashoffset = this.DOM.home3.querySelector('path').getTotalLength()


    this.DOM.trazo.querySelector('path').style.strokeDasharray = this.DOM.trazo.querySelector('path').getTotalLength()
    this.DOM.trazo.querySelector('path').style.strokeDashoffset = this.DOM.trazo.querySelector('path').getTotalLength()


    this.object = {
      home1:this.DOM.home1.querySelector('path').getTotalLength(),
      home3:this.DOM.home3.querySelector('path').getTotalLength(),
      trazo:this.DOM.trazo.querySelector('path').getTotalLength()
    }
    
    this.max = {
      home1:this.DOM.home1.querySelector('path').getTotalLength(),
      home3:this.DOM.home3.querySelector('path').getTotalLength(),
      trazo:0
    }

    this.anim = gsap.timeline({paused:true,onComplete:()=>{
      this.DOM.el.classList.add('act')
      this.animyoyo.play()
    }})
    .to(this.object,{
      trazo:this.max.trazo,
      duration:3,
      onUpdate:()=>{
        this.DOM.trazo.querySelector('path').style.strokeDashoffset = this.object.trazo

      }
    },.6)
    .to(this.DOM.el.parentNode,{opacity:1,duration:.6},0)
    .to(this.object,{
      home1:0,
      duration:1,
      onUpdate:()=>{
        this.DOM.home1.querySelector('path').style.strokeDashoffset = this.object.home1

      }
    },.6)
    .to(this.object,{
      home3:0,
      duration:1,
      onUpdate:()=>{
        this.DOM.home3.querySelector('path').style.strokeDashoffset = this.object.home3
        
      }
    },.6)
    .fromTo(this.DOM.home2,
    {
      scaleX:.5,
      opacity:0,
    },
    {
      opacity:1,
      scaleX:1,
      duration:.6,
      delay:1,
     ease:"jumpy"
    },.6)

    this.animnormal = gsap.timeline({paused:true,repeat:-1})
    .to(this.DOM.face,
      {ease:'none',rotate:360+'deg',duration:3}
    ,0)
    
    this.animyoyo = gsap.timeline({paused:true,repeat:-1,yoyo:true,repeatDelay:2,delay:1.2})
    .fromTo(this.DOM.home2,
      {
        scaleX:1,
      },
      {
        scaleX:.7,
        duration:.5,
        delay:.5,
       ease:"jumpy",
       immediateRender:false,
      },0)
      .fromTo(this.DOM.home2,
        {
          scaleX:.7,
        },
        {
          scaleX:1,
          duration:.5,
         ease:"jumpy",
         immediateRender:false,
        },1)
    .fromTo(this.object,
      {
        home1:0,
      },
      {
      home1:this.max.home1,
      immediateRender:false,
      duration:1,
      onUpdate:()=>{
        this.DOM.home1.querySelector('path').style.strokeDashoffset = this.object.home1
        
      }
    },.5)
    
    .fromTo(this.object,
      {
        home3:0
      },
      {
      home3:this.max.home3,
      immediateRender:false,
      duration:1,
      onUpdate:()=>{
        this.DOM.home3.querySelector('path').style.strokeDashoffset = this.object.home3
        
      }
    },0)
    
    
    // if(this.device > 1){
    //   this.anim
      
    // }
    // else{
    //   this.anim
    
    // }
    


  
  }
  
  async start(){
    this.anim.play()
    this.animnormal.play()
  }
  initEvents(){

  }

  removeEvents(){
    
  }

  onResize(){
  }
}
