import Page from '/js🧠🧠🧠/page👁️/pagemain.js'

//COMPS
import Intro from './0Intro'
import Stext from './Stext'
import Introio from './0Intro/io.js'



class Home extends Page {
  constructor (main) {
    super(main)
  }

  async create(content,main,temp=undefined) {
    super.create(content,main)
    if(temp!=undefined){

      document.querySelector('#content').insertAdjacentHTML('afterbegin',temp)
    }
    else{
      let data = await this.loadRestApi(this.main.base+'/wp-json/wp/v2/pages/',content.dataset.id,content.dataset.template)
      document.querySelector('#content').insertAdjacentHTML('afterbegin',data.csskfields.html)
    }
    this.el = document.querySelector('main')
    

    this.DOM = {
      el:this.el
    }

    await this.loadImages()
    await this.loadVideos()
    


    await this.createComps()
    await this.createIos()
    

    await this.getReady()
  }
  iOpage(animobj){
    if(animobj.el.classList.contains('iO-scrollLine')){
      animobj.class = new Introio(animobj,this.main.device) 
      

    }
    else if(animobj.el.classList.contains('iO-Stext')){
      animobj.class = new Stext(animobj,this.main.device,this.main.isTouch) 
      

    }
    
    
    return animobj
  }

  
  
  async createComps(){
   


    await super.createComps()
    if(this.DOM.el.querySelector('.about_intro')){
      this.components.intro = new Intro(this.DOM.el.querySelector('.about_intro'),this.main.device)
    
    }
    
    

  }


  async animIntro(val){
    if(this.components.intro){
      await this.components.intro.start()
    

    
    }

    // document.documentElement.style.setProperty("--ck_other", '#fff')
    // document.documentElement.style.setProperty("--ck_accent", '#050505')
    gsap.to("html", {"--ck_other": "#fff", duration: .45})
    gsap.to("html", {"--ck_accent": "#050505", duration: .45})


    return val
   
  }

  async animOut(){

    await gsap.to(this.DOM.el,{opacity:0})
    gsap.to("html", {"--ck_other": "#050505", duration: .45})
    gsap.to("html", {"--ck_accent": "#fff", duration: .45})

  }

}




export default Home