
import './index.scss'


export default class {
  constructor (obj,device,isTouch) {
    this.el = obj.el
    
    this.DOM = {
      el : this.el.parentNode,
      real : this.el.parentNode.querySelector('.Stext_real'),
      fake : this.el.parentNode.querySelector('.Stext_fake'),
      hold : this.el.parentNode.querySelector('.Stext_hold'),

    }

    this.act = 0
    this.isupdate = 2
    

    this.speed = {
      current:0,
      target:0,
      static:0
    }

    this.create()
  }

  timeout(ms){
    return new Promise(resolve => setTimeout(resolve, ms))
  }
  
  


  create(){
    while(this.DOM.real.clientWidth < window.innerWidth){
      this.DOM.real.appendChild(this.DOM.real.querySelector('.Sel').cloneNode(true));
    }
    this.DOM.fake.innerHTML = this.DOM.real.innerHTML
    
    this.createAnim()
  }

  createAnim(){
    this.anim = gsap.timeline({paused:true,repeat:-1})    
    .fromTo(this.DOM.hold,{x:0},{x:-1*this.DOM.real.clientWidth+'px',ease:'none',duration:10},0)
  }

  check(entry,pos){
    let vis = false
    if(entry.boundingClientRect.height == 0){
      return false
    }
    
    if(entry.isIntersecting == true){
      vis = true
      this.start()

    }
    else{
      
      this.stop()
    }


    return vis

  }
  async start(){
    this.active = 1
    this.anim.play()
   

  }
  
  stop(){
    this.active = 0
    this.anim.pause()
    
    
  }

  initEvents(){
   

  }
  removeEvents(){

  }
  update(speed){

    this.speed.target = Math.abs(speed*.06)
    this.speed.current = gsap.utils.interpolate(this.speed.current,this.speed.target,0.8)
    this.anim.timeScale(1+this.speed.current)
  }

  onResize(){
    this.w = window.innerWidth
    this.h = window.innerHeight
    this.max = this.el.clientHeight + this.h
    this.anim.killTweensOf(this.DOM.hold)
    this.createAnim()
  
  }
  
  
}
