
// import './index.scss'
//import SplitType from 'split-type'

export default class {
  constructor (el,device) {


    this.DOM = {
      el:el,
      trazo:el.querySelector('.trazo svg'),
    }
    this.device = device
    this.active = 0

    this.create()
    
  }

  async create(){
   

    this.DOM.trazo.querySelector('path').style.strokeDasharray = this.DOM.trazo.querySelector('path').getTotalLength()
    this.DOM.trazo.querySelector('path').style.strokeDashoffset = this.DOM.trazo.querySelector('path').getTotalLength()


    this.object = {
      trazo:this.DOM.trazo.querySelector('path').getTotalLength()
    }
    
    this.max = {
      trazo:0
    }

    this.anim = gsap.timeline({paused:true,onComplete:()=>{
      this.DOM.el.classList.add('act')
    }})
    .to(this.object,{
      trazo:this.max.trazo,
      duration:3,
      onUpdate:()=>{
        this.DOM.trazo.querySelector('path').style.strokeDashoffset = this.object.trazo

      }
    },.6)
    .to(this.DOM.el.parentNode,{opacity:1,duration:.6},0)
    
    
    // if(this.device > 1){
    //   this.anim
      
    // }
    // else{
    //   this.anim
    
    // }
    


  
  }
  
  async start(){
    this.anim.play()
  }
  initEvents(){

  }

  removeEvents(){
    
  }

  onResize(){
  }
}
