
// import './index.scss'
//import SplitType from 'split-type'

export default class {
  constructor (el,device) {


    this.DOM = {
      el:el,
    }
    this.device = device
    this.active = 0

    this.create()
    
  }

  async create(){
   


    this.anim = gsap.timeline({paused:true,onComplete:()=>{
      this.DOM.el.classList.add('act')
    }})
    .to(this.DOM.el.parentNode,{opacity:1,duration:.6},0)
    .to(this.DOM.el.querySelector('.proyecto_intro_move'),{left:0,duration:1.4,ease:Power2.easeInOut},0)
    .fromTo(this.DOM.el.querySelector('.proyecto_intro_move img'),{xPercent:40},{xPercent:0,duration:1.7,ease:Power2.easeInOut},0)
    
    
    // if(this.device > 1){
    //   this.anim
      
    // }
    // else{
    //   this.anim
    
    // }
    


  
  }
  
  async start(){
    this.anim.play()
  }
  initEvents(){

  }

  removeEvents(){
    
  }

  onResize(){
  }
}
