'use strict';
export default class {
  constructor(el){
    this.DOM = {
      el: el,
    };
    this.initEvents()
  }
  initEvents() {

      let a = this.DOM.el

      new window.SplitType(a.querySelector('.rel'),{types:'chars'})
      new window.SplitType(a.querySelector('.ghost'),{types:'chars'})

      let ghosts = a.querySelectorAll('.ghost .char')
      this.animin = gsap.timeline({paused:true})
      
      for(let [u,b] of a.querySelectorAll('.rel .char').entries()){
        this.animin
        .to(b,{yPercent:-20,opacity:0,duration:.2,ease:Power2.easeInOut},u*0.05)
        .to(ghosts[u],{yPercent:-50,opacity:1,duration:.2,ease:Power2.easeInOut},(u*0.05 + .1))
      }
      
      this.animout = gsap.timeline({paused:true})
      for(let [u,b] of a.querySelectorAll('.rel .char').entries()){
        this.animout
        .to(ghosts[u],{yPercent:0,duration:.2,opacity:0,ease:Power2.easeInOut},u*0.05)
        .to(b,{yPercent:0,duration:.25,opacity:1,ease:Power2.easeInOut},(u*0.05 + .1))
      }


      a.onmouseenter = () =>{
        if(this.animout){
          this.animout.pause()
        }
        // window.gsap.killTweensOf(this.animin)
        

        this.animin.progress(0)
        this.animin.play()
      }


      a.onmouseleave = () =>{
        if(this.animin){
          this.animin.pause()
        }
        // window.gsap.killTweensOf(this.animin)
        

        this.animout.progress(0)
        this.animout.play()
      }
  }
  
  removeEvents(){

  }

}