
import './index.scss'

class Nav {
  constructor (main) {
   
    this.main = main
    this.isOpen = 0

  }

  async create (temp) {

    document.querySelector('body').insertAdjacentHTML('afterbegin',temp)

   let el = document.querySelector('.nav')
    this.DOM = {
      el:el,
      burger:el.querySelector('.nav_burger'),
      ops:el.querySelectorAll('.ops_el'),
    }


    gsap.set(this.DOM.el,{opacity:0})
    


    this.initEvents()
  }
  async openMenu(){
    

    document.documentElement.classList.add('act-menu')
    document.dispatchEvent(this.main.events.openmenu)
  }
  async closeMenu(){
    document.documentElement.classList.remove('act-menu')
    document.dispatchEvent(this.main.events.closemenu)
    
  }

  async show(){

    gsap.fromTo(this.DOM.el,{opacity:0},{opacity:1,ease:Power2.easeInOut,duration:.6})
    
  }
  async hide(){
    
    gsap.to(this.DOM.el,{opacity:0,ease:Power2.easeInOut,duration:.6})
  }

  initEvents(){
    if(this.DOM.burger){
      this.DOM.burger.onclick = () =>{
        if(this.isOpen == 1){
          this.closeMenu()
          this.isOpen = 0
        }
        else{
          this.openMenu()
          this.isOpen = 1
        }
      }
    }
    if(this.DOM.ops){
      this.animin = []
      this.animout = []
      for(let [i,a] of this.DOM.ops.entries()){
        new window.SplitType(a.querySelector('.rel'),{types:'chars'})
        new window.SplitType(a.querySelector('.ghost'),{types:'chars'})

        let ghosts = a.querySelectorAll('.ghost .char')
        this.animin.push(gsap.timeline({paused:true}))
        for(let [u,b] of a.querySelectorAll('.rel .char').entries()){
          this.animin[i]
          .to(b,{yPercent:-20,opacity:0,duration:.2,ease:Power2.easeInOut},u*0.05)
          .to(ghosts[u],{yPercent:-50,opacity:1,duration:.2,ease:Power2.easeInOut},(u*0.05 + .1))
        }
        
        this.animout.push(gsap.timeline({paused:true}))
        for(let [u,b] of a.querySelectorAll('.rel .char').entries()){
          this.animout[i]
          .to(ghosts[u],{yPercent:0,duration:.2,opacity:0,ease:Power2.easeInOut},u*0.05)
          .to(b,{yPercent:0,duration:.25,opacity:1,ease:Power2.easeInOut},(u*0.05 + .1))
        }


        a.onmouseenter = () =>{
          if(this.animout[i]){
            this.animout[i].pause()
          }
          // window.gsap.killTweensOf(this.animin[i])
          

          this.animin[i].progress(0)
          this.animin[i].play()
        }


        a.onmouseleave = () =>{
          if(this.animin[i]){
            this.animin[i].pause()
          }
          // window.gsap.killTweensOf(this.animin[i])
          

          this.animout[i].progress(0)
          this.animout[i].play()
        }
      }
    }

  }
  

  onResize(){
  }

}



export default Nav